import { NgModule } from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
@NgModule({
  exports: [
    MatToolbarModule,
    MatButtonModule
  ]
})
export class AngularMaterialModule {}
