<mat-toolbar>
  <span>My Application</span>
</mat-toolbar>
<h1 class="prettyH1">Girls For Coding successful</h1>
<p>
  Riley & Colette Building for the future  
</p>

<p>button should show below</p>

<button class="pink" mat-button (click)=coletteFavoriteColor()>there is no words here</button>
<p>button should show above</p>

